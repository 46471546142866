import { LoadingButton } from '@mui/lab';
import { Button, Grid, styled } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { createIngredientCategory, createIngredientSubCategory, deleteIngredientCategory, deleteIngredientSubCategory, getIngredientCategoryById, updateIngredientCategory } from '../../../setup/api/ingredientCategory';
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from "../../../setup/hooks/useFetchWithAlert";
import { IngredientCategory, IngredientSubCategory } from "../../../setup/models";
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { ComparTextField } from "../../../ui/components/TextField/ComparTextField";
import { TitleHeading } from "../../../ui/components/Titles/Title";

export const AddIngredientCategoryPage: FC = () => {
    const [ingredientCategory, setIngredientCategory] = useState<Partial<IngredientCategory>>({});

    const { id } = useParams<{ id: string }>();

    const create = useFetchWithAlert(alertPayload(`Ingredient category created`));
    const update = useFetchWithAlert(alertPayload(`Ingredient category updated`));
    const deleted = useFetchWithAlert(alertPayload(`Ingredient category deleted`));

    const [newSubCategoryName, setNewSubCategoryName] = useState<string>(``);
    const [newSubCategoryError, setNewSubCategoryError] = useState<string>(``);

    const [requestError, setRequestError] = useState<string>(``);

    const { request } = useFetch();
    const history = useHistory();

    const handleSubmit = (values: any) => {
        setRequestError(``);
        if (id) {
            update.request(updateIngredientCategory, id, values)
                .then(res => {
                    if (!res.id) {
                        setRequestError(res);
                    }
                })
                .catch(error => {
                    setRequestError(error);
                    console.log(error);
                });
        } else {
            create.request(createIngredientCategory, values)
                .then(res => {
                    if (res.id) {
                        history.replace(`/ingredientCategory/${res.id}`);
                    } else {
                        setRequestError(res);
                    }
                })
                .catch(error => {
                    setRequestError(error);
                    console.log(error);
                });
        }
    };

    const deleteIngredientCategoryHandler = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                deleted.request(deleteIngredientCategory, id).then(deleteCategory => {
                    if (deleteCategory.status === 200) {
                        history.replace(`/ingredientCategories`);
                    } else if (deleteCategory.message && deleteCategory.message.includes(`violates foreign key constraint`)) {
                        setRequestError(`The category has sub categories. Remove them first`);
                    } else {
                        setRequestError(`Error message: ${deleteCategory.message}`);
                    }
                });
            }
        }
    };

    const removeSubCategory = (id: string) => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text)) {
                deleted.request(deleteIngredientSubCategory, id)
                    .then(res => {
                        if (res.status === 200) {
                            setIngredientCategory(prevCategory => ({
                                ...prevCategory,
                                subCategories: prevCategory.subCategories
                                    ? prevCategory.subCategories.filter(subCategory => subCategory.id !== id)
                                    : []
                            }));
                        }
                        setRequestError(``);
                    })
                    .catch(error => {
                        console.error(`Error deleting subcategory:`, error);
                    });
            }
        }
    };

    const addSubCategory = (id: string | undefined) => {
        if (newSubCategoryName) {
            if (id) {
                const payload = {
                    name: newSubCategoryName
                };

                create.request(createIngredientSubCategory, id, payload)
                    .then(res => {
                        const createdSubCategory: IngredientSubCategory = res;

                        setIngredientCategory(prevCategory => {
                            const exists = prevCategory.subCategories && prevCategory.subCategories.some(subCategory => subCategory.id === createdSubCategory.id);

                            if (!exists) {
                                return {
                                    ...prevCategory,
                                    subCategories: [
                                        ...(prevCategory.subCategories || []),
                                        createdSubCategory
                                    ]
                                };
                            }
                            return prevCategory;
                        });
                        setNewSubCategoryName(``);
                    });
            }
        } else {
            setNewSubCategoryError(`Please fill out sub category name`);
        }
    };

    useEffect(() => {
        if (id) {
            request(getIngredientCategoryById, id)
                .then(res => {
                    setIngredientCategory(res);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [id]);

    return <ModalCompar
        open={true}
        onClose={() => {
            history.goBack();
        }}
        title={`Ingredient`}>
        <Formik
            initialValues={ingredientCategory}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {(props: FormikProps<Partial<IngredientCategory>>) => (
                <Form
                    onChange={() => {
                        setRequestError(``);
                    }}
                >
                    <Grid container justifyContent={`space-between`} padding={1}>
                        <TagFormGrid item xs={12} justifyContent={`space-between`} direction={`row`}>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid xs={5}>
                                    <TitleHeading children="Ingredient Category information" style={{
                                        fontSize: `1.2rem`,
                                        paddingBottom: `1em`
                                    }} />
                                    <ComparTextField
                                        type='string'
                                        label='Name'
                                        required={true}
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder='Name'
                                        {...props.getFieldProps(`name`)}
                                    />
                                    {props.values.subCategories?.map(item => (
                                        <div style={{
                                            display: `flex`,
                                            flexDirection: `row`,
                                            justifyContent: `space-between`,
                                            marginTop: `1.2em`
                                        }}>
                                            <TitleHeading children={item.name} style={{
                                                fontSize: `1.0rem`,
                                                paddingBottom: `1em`
                                            }} />
                                            <Button
                                                component="label"
                                                children={`Remove`}
                                                onClick={() => removeSubCategory(item.id)}
                                                style={{
                                                    backgroundColor: `#FFF`,
                                                }}
                                            />
                                        </div>
                                    ))}

                                </Grid>
                                {id &&
                                    <Grid xs={6}>
                                        <TitleHeading children="Add new subcategory" style={{
                                            fontSize: `1.2rem`,
                                            paddingBottom: `1em`
                                        }} />
                                        <ComparTextField
                                            type='string'
                                            label='Name'
                                            style={{
                                                backgroundColor: `#FFF`,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            placeholder='Name (will automatically be uppercase)'
                                            value={newSubCategoryName}
                                            onChange={event => {
                                                setNewSubCategoryError(``);
                                                setNewSubCategoryName(event.target.value);
                                            }}
                                        />
                                        <Button
                                            component="label"
                                            children={`Add ${newSubCategoryName}`}
                                            onClick={() => addSubCategory(id)}
                                            style={{
                                                backgroundColor: `#FFF`,
                                            }}
                                        />
                                        {newSubCategoryError && <p style={{ color: `red` }}>{newSubCategoryError}</p>}
                                    </Grid>
                                }
                            </Grid>
                            <div style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                                marginTop: `1.2em`
                            }}>
                                <LoadingButton
                                    loading={id ? update.isLoading : create.isLoading}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ flex: `end`, marginTop: `1em` }}
                                    children={id ? `Update ${props.values.name}` : `Create ingredient category`} />
                                {id &&
                                    <Button
                                        component="label"
                                        children="Delete ingredient category"
                                        onClick={deleteIngredientCategoryHandler}
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                    />
                                }
                            </div>
                            {requestError && <p style={{ color: `red` }}>{requestError}</p>}
                        </TagFormGrid>
                    </Grid>
                </Form>)}
        </Formik>
    </ModalCompar>;
};

function renderTestRowsUsers(headings: [Array<string>], item: Record<string, string>) {
    return headings.map(([key]) => (
        <td key={key} className='listHover'>
            {item[key]}
        </td>
    ));
}

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));