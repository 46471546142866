import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, styled } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { countries } from '../../../countries';
import { createRetailer, deleteRetailerById, getRetailerById, updateRetailer } from "../../../setup/api/retail";
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from "../../../setup/hooks/useFetchWithAlert";
import { Retailer } from "../../../setup/models";
import SelectField from '../../../ui/components/FieldSelect/FieldSelectWithoutFormix';
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { ComparTextField } from "../../../ui/components/TextField/ComparTextField";
import { TitleHeading } from "../../../ui/components/Titles/Title";

export const AddRetailerModal: FC = () => {
    const [retailer, setRetailer] = useState<Partial<Retailer>>({});

    const { id } = useParams<{ id: string }>();

    const create = useFetchWithAlert(alertPayload(`Retailer created`));
    const update = useFetchWithAlert(alertPayload(`Retailer updated`));
    const deleted = useFetchWithAlert(alertPayload(`Retailer deleted`));

    const [countryValue, setCountryValue] = useState<{ id: string, value: string, currency: string }>({ id: `null`, value: `No country`, currency: `DKK` });

    const [requestError, setRequestError] = useState<string>(``);

    const { request } = useFetch();
    const history = useHistory();

    const handleSubmit = (values: any) => {
        setRequestError(``);

        const payload = {
            ...values,
            country: countryValue.id
        };

        if (id) {
            update.request(updateRetailer, id, payload)
                .then(res => {
                    if (!res.id) {
                        setRequestError(res);
                    }
                })
                .catch(error => {
                    setRequestError(error);
                    console.log(error);
                });
        } else {
            create.request(createRetailer, payload)
                .then(res => {
                    if (res.id) {
                        history.replace(`/retailer/${res.id}`);
                    } else {
                        setRequestError(res);
                    }
                })
                .catch(error => {
                    setRequestError(error);
                    console.log(error);
                });
        }
    };

    const deleteRetailerHandler = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                deleted.request(deleteRetailerById, id).then(deleteRetailer => {
                    if (deleteRetailer.status === 200) {
                        history.replace(`/retailers`);
                    } else {
                        setRequestError(`Error message: ${deleteRetailer.message}`);
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (id) {
            request(getRetailerById, id)
                .then(res => {
                    const country = countries.find(country => country.id === res.country);
                    const countryValue = country ? { id: country.id, value: country.value, currency: country.currency } : { id: `null`, value: `No country`, currency: `DKK` };

                    setCountryValue(countryValue);
                    setRetailer(res);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [id]);

    return <ModalCompar
        open={true}
        onClose={() => {
            history.goBack();
        }}
        title={`Ingredient`}>
        <Formik
            initialValues={retailer}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {(props: FormikProps<Partial<Retailer>>) => (
                <Form
                    onChange={() => {
                        setRequestError(``);
                    }}
                >
                    <Grid container justifyContent={`space-between`} padding={1}>
                        <TagFormGrid item xs={12}>
                            <TitleHeading children="Retailer information" style={{
                                fontSize: `1.2rem`,
                                paddingBottom: `1em`
                            }} />
                            <Grid xs={5}>
                                <ComparTextField
                                    type='string'
                                    label='Name'
                                    required={true}
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Name'
                                    {...props.getFieldProps(`name`)}
                                />
                                <SelectField
                                    name='country'
                                    label='Country'
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}
                                    style={{
                                        backgroundColor: `#FFF`,
                                        border: `none`,
                                    }}
                                    value={countryValue}
                                    onChange={value => {
                                        if (value) {
                                            setCountryValue(value);
                                        }
                                    }}
                                    options={countries}
                                    getOptionLabel={option => option.value}
                                    width={`100%`}
                                />
                                <ComparTextField
                                    type='string'
                                    label='Website'
                                    required={true}
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Website'
                                    {...props.getFieldProps(`website`)}
                                />
                                <ComparTextField
                                    type='string'
                                    label='Affiliate Link'
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Affiliate Link'
                                    {...props.getFieldProps(`affiliateLink`)}
                                />
                                {countryValue.id === `null` &&
                                    <ComparTextField
                                        type='string'
                                        label='Default Currency'
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                        required={countryValue.id === `null`}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder='Default Currency'
                                        {...props.getFieldProps(`defaultCurrency`)}
                                    />
                                }
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...props.getFieldProps(`hasPicture`)}
                                            checked={props.values.hasPicture || false}
                                            onChange={props.handleChange}
                                            value={props.values.hasPicture || false}
                                            color="primary"
                                        />
                                    }
                                    label="Have you uploaded a retailer picture to S3?"
                                />
                            </Grid>
                            <div style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                                marginTop: `1.2em`
                            }}>
                                <LoadingButton
                                    loading={id ? update.isLoading : create.isLoading}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ flex: `end`, marginTop: `1em` }}
                                    children={id ? `Update ${props.values.name}` : `Create retailer`} />
                                {id &&
                                    <Button
                                        component="label"
                                        children="Delete retailer"
                                        onClick={deleteRetailerHandler}
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                    />
                                }
                                {requestError && <p style={{ color: `red` }}>{requestError}</p>}
                            </div>
                        </TagFormGrid>
                    </Grid>
                </Form>)}
        </Formik>
    </ModalCompar>;
};

function renderTestRowsUsers(headings: [Array<string>], item: Record<string, string>) {
    return headings.map(([key]) => (
        <td key={key} className='listHover'>
            {item[key]}
        </td>
    ));
}

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));