import config from 'react-global-configuration';

import { Discount } from '../../global';

export const getDiscountList = (queries: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/discount${queries ? queries : ``}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getDiscountById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/discount/${id}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const createDiscount = (payload: Partial<Discount>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/discount`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const updateDiscount = (id: string, payload: Partial<Discount>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/discount/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const deleteDiscountById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/article/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});