import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Checkbox, FormControlLabel, Grid, styled } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";

import { checkIngredientByName, createNewIngredient } from "../../../setup/api/ingredient";
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from "../../../setup/hooks/useFetchWithAlert";
import { ProductIngredient } from "../../../setup/models";
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { StringTableDisplay } from '../../../ui/components/StringTableDisplay/StringTableDisplay';
import { ComparTextField } from '../../../ui/components/TextField/ComparTextField';
import { TitleHeading } from "../../../ui/components/Titles/Title";

export const CreateIngredientModal: FC = () => {
    const [ingredient] = useState<Partial<ProductIngredient>>({});

    const [cancer, setCancer] = useState<string>(``);
    const [allergies, setAllergies] = useState<string>();
    const [developmental, setDevelopmental] = useState<string>();
    const [restrictions, setRestrictions] = useState<string>();

    const [functions, setFunctions] = useState<string[]>([]);
    const [functionString, setFunctionString] = useState<string>();

    const [error, setError] = useState<string>();

    const { request, abortController } = useFetch();
    const create = useFetchWithAlert(alertPayload(`Ingredient Created `));
    const history = useHistory();

    const handleSubmit = (values: any) => {
        request(checkIngredientByName, values.name).then(result => {
            if (result.id) {
                setError(`${values.name} already exists in database`);
            } else {
                let score = null;
                let nhj = false;
                let concernsPost;

                if (values.score !== `` && values.score) {
                    score = values.score;
                }

                if (values.naturalHairJourney) {
                    nhj = values.naturalHairJourney;
                }

                if (score && cancer !== `` && allergies !== `` && developmental !== `` && restrictions !== ``) {
                    concernsPost = [
                        { "name": `Cancer`, "value": cancer },
                        { "name": `Allergies & Immunotoxicity`, "value": allergies },
                        { "name": `Developmental and Reproductive Toxicity`, "value": developmental },
                        { "name": `Use Restrictions`, "value": restrictions }
                    ];
                }
                let functionsPost;

                if (functions.length > 0) {
                    functionsPost = functions;
                }

                const payload: any = {
                    ...values,
                    functions: functionsPost,
                    concerns: concernsPost,
                    score: score,
                    naturalHairJourney: nhj,
                };

                create.request(createNewIngredient, payload, abortController.signal)
                    .then(res => {
                        console.log(res.id);
                        history.replace(`/ingredient/${res.id}`);
                    });
            }
        });
    };

    const appendFunction = () => {
        if (functionString) {
            const updatedFunctionArray = [...functions, functionString?.toUpperCase() || ``];

            setFunctions(updatedFunctionArray);
            setFunctionString(``);
        }
    };

    return <ModalCompar
        open={true}
        onClose={() => {
            history.goBack();
        }}
        title={`Ingredient`}>
        <Formik
            initialValues={ingredient}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {(props: FormikProps<Partial<ProductIngredient>>) => (
                <Form>
                    <Grid container justifyContent={`space-between`} padding={1}>
                        <TagFormGrid item xs={12}>
                            <TitleHeading children="Ingredient information" style={{
                                fontSize: `1.2rem`,
                                paddingBottom: `1em`
                            }} />
                            <ComparTextField
                                style={{
                                    backgroundColor: `#FFF`,
                                }}
                                type='string'
                                placeholder='Name'
                                label='Name'
                                InputLabelProps={{ shrink: true }}
                                {...props.getFieldProps(`name`)}
                            />
                            <ComparTextField
                                style={{
                                    backgroundColor: `#FFF`,
                                }}
                                type='number'
                                label='EWG Score'
                                InputLabelProps={{ shrink: true }}
                                placeholder='Score (remember to provide the highest)'
                                {...props.getFieldProps(`score`)}
                            />
                            {props.values.score && (
                                <ComparTextField
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    type='string'
                                    label='EWG link'
                                    InputLabelProps={{ shrink: true }}
                                    required={props.values.score !== undefined}
                                    placeholder='For example: /skindeep/ingredients/706945-water/'
                                    {...props.getFieldProps(`EWGLink`)}
                                />
                            )}
                            <ComparTextField
                                // disabled
                                style={{
                                    backgroundColor: `#FFF`,
                                }}
                                type='string'
                                label='CosIng link'
                                InputLabelProps={{ shrink: true }}
                                placeholder='For example: /92472'
                                {...props.getFieldProps(`cosIngLink`)}
                            />
                            <Grid xs={12} display={`flex`}>
                                <TitleHeading children='Functions' style={{
                                    fontSize: `1.1rem`,
                                }} />
                            </Grid>
                            <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                width: `100%`
                            }}>
                                <Grid xs={6} display="flex" alignItems="center" justifyContent="space-between">
                                    <ComparTextField
                                        // disabled
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                        type='string'
                                        label='Function'
                                        InputLabelProps={{ shrink: true }}
                                        placeholder='Function'
                                        value={functionString}
                                        onChange={e => setFunctionString(e.target.value)}
                                    />
                                    <Button type="button"
                                        disabled={!functionString}
                                        variant="contained"
                                        color="primary"
                                        children="Add Function"
                                        onClick={appendFunction}
                                        sx={{
                                            height: `3em`,
                                            width: `10em`,
                                            marginLeft: `1em`,
                                        }} />
                                </Grid>
                            </Grid>
                            {functions.length > 0 && <Grid>
                                <StringTableDisplay
                                    data={functions}
                                    objectsSelected={functions}
                                    setObjectSelected={setFunctions}
                                    titleText='Selected Functions'
                                />
                            </Grid>}
                            {props.values.score && (
                                <>
                                    <Grid xs={12} display={`flex`}>
                                        <TitleHeading children='EWG concerns' style={{
                                            fontSize: `1.1rem`,
                                        }} />
                                    </Grid><Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                        width: `100%`
                                    }}>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='cancer'
                                                label='Cancer'
                                                keyName='cancer'
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setCancer(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='allergies'
                                                label='Allergies & Immunotoxicity'
                                                keyName='allergies'
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setAllergies(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='developmental'
                                                label='Developmental and Reproductive Toxicity'
                                                keyName='developmental'
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setDevelopmental(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='restrictions'
                                                label='Use Restrictions'
                                                keyName='restrictions'
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setRestrictions(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Grid xs={12} display={`flex`} justifyContent={`space-between`} marginTop={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...props.getFieldProps(`naturalHairJourney`)}
                                            checked={props.values.naturalHairJourney || false}
                                            onChange={props.handleChange}
                                            value={props.values.naturalHairJourney || false}
                                            color="primary"
                                        />
                                    }
                                    label="Natural Hair Journey"
                                />
                                <LoadingButton
                                    disabled={props.values.name === `` || props.values.name === undefined}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ flex: `end` }}
                                    children="Create ingredient" />
                            </Grid>
                            <Grid xs={12} display={`flex`} justifyContent={`center`}>
                                <TitleHeading children={error} style={{
                                    fontSize: `0.75em`,
                                    color: `red`
                                }} />
                            </Grid>
                        </TagFormGrid>
                    </Grid>
                </Form>)}
        </Formik>
    </ModalCompar>;
};

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));