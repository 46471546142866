export const toLocalISOString = (date: Date) => {
    // Get the timezone offset in minutes and convert it to milliseconds
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    // Adjust the date to the local timezone by subtracting the offset
    const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString()
        .slice(0, 16);

    return localISOTime;
};

export const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
        year: `numeric`,
        month: `long`,
        day: `numeric`,
        hour: `numeric`,
        minute: `numeric`,
    };

    return new Date(dateString).toLocaleString(undefined, options);
};

export function isDateInPast(dateString: string): boolean {
    const date = new Date(dateString);

    return date < new Date(); // Check if the date is before the current date
}